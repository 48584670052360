import { graphql } from "gatsby"
import React, { FC, RefObject, useCallback, useRef, useState } from "react"
import { Layout } from "src/components/Layout"

import CommonQuestions from "src/components/CommonQuestions"
import Plans from "./Plans"
import PlansTable from "./PlansTable"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import {
  handleSetPayMonthly,
  handleSetPayYearly,
  parseMarkdowns,
} from "./logic"
import Logos from "src/images/pricing-logos.png"
import CapterraRating from "src/images/Capterra-Rating.png"
import CG2Rating from "src/images/G2-Crowd-Rating.png"

import ArrowLink from "src/components/ArrowLink"
import { usePages } from "src/context/pages"
import { JsonLd } from "gatsby-plugin-next-seo"
import Link from "src/utils/Link"
import Logo from "src/images/structured-data-logo.png"

const Template: FC<Props> = ({
  data: {
    allFile: { nodes: markdowns },
    file: {
      childYaml: { plans, plansTable, ...texts },
    },
  },
}) => {
  const sectionNames = texts.questions.sections
  const sections = parseMarkdowns({ markdowns, sectionNames })
  const [payYearly, setPayYearly] = useState(true)

  const onSetPayYearly = useCallback(handleSetPayYearly({ setPayYearly }), [
    setPayYearly,
  ])

  const onSetPayMonthly = useCallback(handleSetPayMonthly({ setPayYearly }), [
    setPayYearly,
  ])
  //const [tableIsOpen, setTableIsOpen] = useState(false)
  //const openTable = useCallback(() => setTableIsOpen(true), [])

  const scrollToRef = (ref: RefObject<any>) =>
    window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: "smooth" })
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  const goToAndOpen = () => {
    setTimeout(() => executeScroll(), 10)
    //openTable()
  }
  const { getPage, currentPath } = usePages()
  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "Service",
          "@id": "https://www.getflow.com/basic/#service",
          name: "Basic: Team Plan",
          description: "",
          url: "https://www.getflow.com/basic/",
          mainEntityOfPage: "https://www.getflow.com/basic/",
          image: "",
        }}
      />
      <div className={styles.content}>
        <div className="content-wrapper">
          <h1 className={styles.title}>{texts.choosePlanHeader}</h1>
          <div className={styles.offerDescription}>
            <span>{texts.freeFor30}</span>
            <span>{texts.creditCard}</span>
            <span>{texts.cancel}</span>
          </div>

          <div className={styles.switch}>
            <div
              className={`${styles.switchContent} ${
                payYearly ? styles.right : styles.left
              }`}
            >
              <button
                className={`${styles.switchTab} ${
                  !payYearly ? styles.active : ""
                }`}
                onClick={onSetPayMonthly}
              >
                {texts.paySwitch.monthly}
              </button>
              <button
                className={`${styles.switchTab} ${
                  payYearly ? styles.active : ""
                }`}
                onClick={onSetPayYearly}
              >
                {texts.paySwitch.yearly}
              </button>
            </div>
          </div>

          <Plans
            plans={plans}
            isMonthly={!payYearly}
            texts={texts}
            className={styles.plans}
          />
          <button onClick={goToAndOpen} className={styles.compareLink}>
            {texts.compareLinkText}
          </button>
          <div className={styles.matterContent}>
            <p className={styles.matterTitle}>{texts.noMatterTitle}</p>
            <p className={styles.matterDescription}>
              {texts.noMatterDescription}
            </p>
          </div>
          <div className={styles.ratedContent}>
            <div className={styles.rated}>
              <p className={styles.ratedTitle}>{texts.ratedCapterra}</p>
              <Link
                to="https://www.capterra.com/p/150388/Flow/#reviews"
                target="_blank"
              >
                <img src={CapterraRating} />
              </Link>
            </div>
            <div>
              <p className={styles.logosTitle}>{texts.logosTitle}</p>
              <Link className={styles.logosList} to={getPage("customers").url}>
                <img src={Logos} />
              </Link>
            </div>
            <div className={styles.rated}>
              <p className={styles.ratedTitle}>{texts.ratedCrowd}</p>
              <Link
                to="https://www.g2.com/products/flow-flow/reviews#reviews"
                target="_blank"
              >
                <img src={CG2Rating} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.tableSection} id="compareTable" ref={myRef}>
        <div className="content-wrapper">
          <h2 className={`${styles.title} ${styles.dark}`}>
            {texts.comparePlansHeader}
          </h2>
          <PlansTable plansTable={plansTable} texts={texts} isOpen={true} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={`content-wrapper ${styles.faqs}`}>
          <CommonQuestions
            markdowns={sections}
            title={texts.questions.title}
            sectionTitle={false}
          />
          <p className={styles.more}>{texts.more}</p>
          <ArrowLink
            className={styles.moreLink}
            text={texts.moreLink}
            to={getPage("contact").url}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        header
        metaTitle
        metaDescription
        choosePlanHeader
        freeFor30
        creditCard
        cancel
        paySwitch {
          yearly
          monthly
          discount
        }
        plans {
          name
          description
          cost
          mostPopular
          mostPopularText
          save
          monthFree
          relation
          monthly {
            cost
            mostPopular
            save
            monthFree
          }
          features {
            name
          }
        }
        perUser
        perMonth
        buttonText
        compareLinkText
        noMatterTitle
        noMatterDescription
        ratedCapterra
        ratedCrowd
        logosTitle
        comparePlansHeader
        plansTable {
          headers {
            name
          }
          features {
            name
            basicPlan
            plusPlan
            proPlan
          }
        }
        plansTableButton
        questions {
          title
          sections
        }
        more
        moreLink
      }
    }
    allFile(filter: { relativePath: { regex: "/pricing/questions/.+/" } }) {
      nodes {
        relativePath
        childMarkdownRemark {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default Template
