import React, { FC } from "react"
import { Plan } from "../types"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import CheckIcon from "src/components/Icons/Check"
import DoubleCheck from "src/components/Icons/DoubleCheck"
import TripleCheck from "src/components/Icons/TripleCheck"
import { usePages } from "src/context/pages"
import Link from "src/utils/Link"

const Plans: FC<Props> = ({ plans, isMonthly, texts, className }) => {
  const { getPage } = usePages()

  return (
    <div className={`${className} ${styles.plans}`}>
      {plans
        .map(plan => (isMonthly ? { ...plan, ...plan.monthly } : plan))
        .map((plan: Plan) => (
          <div
            className={`${styles.plan} ${
              plan.mostPopular ? styles.planPopular : ""
            }`}
            key={`plan-${plan.name}`}
          >
            <div className={styles.saveContent}>
              <span
                className={`${styles.save} ${
                  isMonthly ? styles.saveMonthly : ""
                }`}
              >
                {plan.save}
              </span>
              <span className={styles.saveTime}>{plan.monthFree}</span>
            </div>

            {plan.mostPopularText && plan.mostPopular && (
              <span className={styles.popularText}>{plan.mostPopularText}</span>
            )}
            <h3 className={styles.planName}>{plan.name}</h3>
            <p className={styles.planDescription}>{plan.description}</p>
            <div className={styles.priceBlock}>
              <span className={styles.dollar}>$</span>
              <span className={styles.price}>{plan.cost}</span>
              <div className={styles.priceTextBlock}>
                <span className={styles.priceText}>{texts.perUser}</span>
                <span className={styles.priceText}>{texts.perMonth}</span>
              </div>
            </div>
            <Link
              className={`${styles.button} ${
                plan.mostPopular
                  ? "button button-second"
                  : "button button-primary"
              }`}
              to={getPage("signup").url}
            >
              {texts.buttonText}{" "}
            </Link>
            <ul className={styles.features}>
              {plan.relation && (
                <li className={`${styles.feature} ${styles.relation}`}>
                  {plan.name == "Plus" ? (
                    <DoubleCheck className={styles.checkIcon} />
                  ) : (
                    <TripleCheck className={styles.checkIcon} />
                  )}
                  <p className={styles.featureName}>{plan.relation}</p>
                </li>
              )}
              {plan.features.map(feature => (
                <li className={styles.feature} key={feature.name}>
                  <CheckIcon className={styles.checkIcon} />
                  <p className={styles.featureName}>{feature.name}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  )
}

export default Plans
