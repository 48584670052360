import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 28"
    fill="currentColor"
    {...props}
  >
    {children}
    <path
      id="Tasks"
      className="cls-1"
      d="M39.94,1.28L23.531,26.9A2.349,2.349,0,0,1,21.557,28H20.626a2.349,2.349,0,0,1-1.974-1.1l-8.5-13.266A1.218,1.218,0,0,1,11.009,12h3a2.409,2.409,0,0,1,1.742,1.453l5.339,8.335L34.342,1.1A2.356,2.356,0,0,1,36.321,0h2.96A0.825,0.825,0,0,1,39.94,1.28ZM21.016,16l-3-4L25.335,1.1A2.356,2.356,0,0,1,27.314,0h2.96a0.825,0.825,0,0,1,.66,1.28ZM14.011,27a3.2,3.2,0,0,1-2.462,1H10.619a2.349,2.349,0,0,1-1.974-1.1l-8.5-13.266A1.218,1.218,0,0,1,1,12H4a2.409,2.409,0,0,1,1.742,1.453L14.011,26v1Z"
    />{" "}
  </svg>
))
