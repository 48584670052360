import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66.22 40"
    fill="currentColor"
    {...props}
  >
    {children}
    <path
      className="cls-1"
      d="M26.4,15h3.75a3,3,0,0,0,2.5-1.33l1.41-1.91h0l7.11-9.61A5.42,5.42,0,0,0,41.8,1a1,1,0,0,0-1-1H37.05a3,3,0,0,0-2.5,1.33L25.71,13.24a.06.06,0,0,0,0,0l-.13.17a1,1,0,0,0-.17.55A1,1,0,0,0,26.4,15Z"
    />
    <path
      className="cls-1"
      d="M65.22,0H61.48A3,3,0,0,0,59,1.34L38.22,32.46,29.47,19.34A3,3,0,0,0,27,18H23.23a1,1,0,0,0-.56.17,1,1,0,0,0-.28,1.39l12.75,19.1A3,3,0,0,0,37.63,40h1.18a3,3,0,0,0,2.49-1.34L66.06,1.55A1,1,0,0,0,66.22,1,1,1,0,0,0,65.22,0Z"
    />
    <path
      className="cls-1"
      d="M35.15,20.32,37,23a1.5,1.5,0,0,0,2.5,0L53.83,1.55A1,1,0,0,0,54,1a1,1,0,0,0-1-1H49.25a3,3,0,0,0-2.5,1.33L35.15,18.65A1.49,1.49,0,0,0,35.15,20.32Z"
    />
    <path
      className="cls-1"
      d="M18.25,19.34A3,3,0,0,0,15.74,18H12a1,1,0,0,0-.56.17,1,1,0,0,0-.27,1.39l12.74,19.1A3,3,0,0,0,26.41,40h1.18a3,3,0,0,0,2.49-1.34l.17-.26a1,1,0,0,0,0-1.11Z"
    />
    <path
      className="cls-1"
      d="M7.25,19.34A3,3,0,0,0,4.74,18H1a1,1,0,0,0-.56.17,1,1,0,0,0-.27,1.39l12.74,19.1A3,3,0,0,0,15.41,40h1.18a3,3,0,0,0,2.49-1.34l.17-.26a1,1,0,0,0,0-1.11Z"
    />
  </svg>
))
