import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import CheckIcon from "src/components/Icons/Check"
import { PlanTable } from "src/templates/pricing/types"

const PlansTable: FC<Props> = ({ plansTable, texts, isOpen, onClickOpen }) => {
  return (
    <div className={`${styles.plansTable} ${isOpen ? styles.tableOpen : ""}`}>
      <div className={styles.table}>
        {plansTable.map((plan: PlanTable) => (
          <div>
            <div className={`${styles.row} ${styles.rowHeader}`}>
              {plan.headers.map(header => (
                <p className={`${styles.tableText} ${styles.title}`}>
                  {header.name}
                </p>
              ))}
            </div>
            {plan.features.map(feature => (
              <div className={`${styles.row} ${styles.rowBody}`}>
                <p className={styles.tableText}> {feature.name} </p>
                {feature.basicPlan == "true" ? (
                  <p className={styles.tableText}>
                    <CheckIcon className={styles.check} />
                  </p>
                ) : feature.basicPlan == "false" ? (
                  <p className={styles.tableText}>
                    <span className={styles.rectangle}></span>
                  </p>
                ) : (
                  <p className={styles.tableText}> {feature.basicPlan} </p>
                )}
                {feature.plusPlan == "true" ? (
                  <p className={styles.tableText}>
                    <CheckIcon className={styles.check} />
                  </p>
                ) : feature.plusPlan == "false" ? (
                  <p className={styles.tableText}>
                    <span className={styles.rectangle}></span>
                  </p>
                ) : (
                  <p className={styles.tableText}> {feature.plusPlan} </p>
                )}
                {feature.proPlan == "true" ? (
                  <p className={styles.tableText}>
                    <CheckIcon className={styles.check} />
                  </p>
                ) : (
                  <p className={styles.tableText}> {feature.proPlan} </p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <button
        onClick={onClickOpen}
        className={`button button-second ${styles.button}`}
      >
        {texts.plansTableButton}
      </button>
      <div className={styles.gradient}></div>
    </div>
  )
}
export default PlansTable
