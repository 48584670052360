import padStart from "lodash/padStart"
import { ChangeEvent, Dispatch, useContext } from "react"
import { Props } from "./types"
import { LangContext } from "src/context/lang"
import groupBy from "lodash/groupBy"

export const handleSetPayYearly = ({
  setPayYearly,
}: {
  setPayYearly: Dispatch<boolean>
}) => () => setPayYearly(true)

export const handleSetPayMonthly = ({
  setPayYearly,
}: {
  setPayYearly: Dispatch<boolean>
}) => () => setPayYearly(false)

export const handleModifyTeamSize = ({
  by,
  calculatorTeamSize,
  setCalculatorTeamSize,
}: {
  by: number
  calculatorTeamSize: number | null
  setCalculatorTeamSize: Dispatch<number | null>
}) => () =>
  setCalculatorTeamSize(validateTeamSize((calculatorTeamSize || 0) + by))

export const handleTeamSizeChange = ({
  setCalculatorTeamSize,
}: {
  setCalculatorTeamSize: Dispatch<number | null>
}) => ({ target }: ChangeEvent<HTMLInputElement>) =>
  setCalculatorTeamSize(validateTeamSizeString(target.value))

export const parseTeamSize = (teamSize: number | null): string =>
  teamSize === null ? "" : padStart(`${teamSize}`, 2, "0")

const validateTeamSize = (teamSize: number | null): number | null =>
  teamSize === null ? null : Math.max(1, teamSize)

const validateTeamSizeString = (teamSizeString: string): number | null => {
  const teamSize = parseInt(teamSizeString)

  if (isNaN(teamSize) || teamSize === 0) {
    return null
  }

  return validateTeamSize(teamSize)
}

export const parseMarkdowns = ({
  markdowns,
  sectionNames,
}: {
  markdowns: Props["markdowns"]
  sectionNames: Props["sectionNames"]
}) => {
  const { lang } = useContext(LangContext)
  const sections = groupBy(
    markdowns
      .map(
        ({
          childMarkdownRemark: {
            frontmatter: { title },
            html,
          },
          relativePath,
        }) => {
          const matches = /pricing\/questions\/(.+)\/(.+)\/(.+)\.md/.exec(
            relativePath,
          )

          if (!matches) {
            return null
          }

          const [, sectionId, questionId, questionLang] = matches

          if (questionLang !== lang) {
            return null
          }

          return {
            sectionId,
            questionId,
            questionTitle: title,
            questionContent: html,
          }
        },
      )
      .filter((it): it is NonNullable<typeof it> => !!it),
    ({ sectionId }) => sectionId,
  )

  return Object.keys(sections)
    .sort((a, b) => a.localeCompare(b))
    .map((key, i) => ({
      id: key,
      name: sectionNames[i],
      questions: sections[key]
        .map(({ questionId, questionTitle, questionContent }) => ({
          id: questionId,
          content: questionContent,
          title: questionTitle,
        }))
        .sort((a, b) => a.id.localeCompare(b.id)),
    }))
}
